import './App.css';
import logo from './assets/logo.png';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h2>I Got Hacked! What do i do?</h2>
        <img src={logo} className="logo" alt="logo" />
        <p>
          <b>Step 1</b>
          <br />
          Find out what was compromised
        </p>
        <p>
          <b>Step 2</b>
          <br />
          Visit{" "}
          <a
            className="link"
            href="https://haveibeenpwned.com/Passwords"
            target="_blank"
          >
            {" "}
            HaveIBeenPwned
            {" "}
          </a> 
          to check if your password was compromised
        </p>
        <p>
          <b>Step 3</b>
          <br />
          If you have been targeted by a virus or malware, Create a bootable USB
          drive and run a full scan on your computer using a trusted antivirus
          software like{" "}
          <a
            className="link"
            href="https://free.drweb.com/aid_admin/"
            target="_blank"
          >
            Dr Web | Live Disk
          </a>
        </p>
        <p>
          <b>Step 4</b>
          <br /> Change all your passwords and enable 2FA on all your accounts
        </p>
        <p>
          <b>Step 5</b>
          <br />
          If you have been targeted by a ransomware, do not pay the ransom. If
          you have no ireplaceable data, wipe your computer and start over from
          scratch
        </p>
        <p>
          <b>Step 6</b>
          <br /> If you have been targeted by a ransomware, do not pay the
          ransom. If you have irreplaceable data, seek professional help to
          recover your data such as{" "}
          <a
            className="link"
            href="https://www.ontrack.com/en-gb/data-recovery/ransomware"
            target="_blank"
          >
            OnTrack
          </a>
        </p>
        <p>
          <b>Step 7</b>
          <br /> If you have done the applicable steps, You should be recovered
          from the attack. If you are still experiencing issues, seek
          professional help
        </p>
      </header>
    </div>
  );
}

export default App;
